:root {
    --color-primary: #CDBD9C;
    //--color-secondary: #6c757d;
    --color-text-default: #1B1B1B;
    --color-background-dark: #1B1B1B;
    --color-border-light: #cecaca;
    //--color-text-muted: #868E96;
    //
    //--color-default: #2D343E;
    --color-white: #FFF;
    --color-light-grey: #F0E9E9;
    //--color-dark-grey: #BEC2C6;
    //--color-dark: #343A40;
    //
    //--color-success: #28A745;
    //--color-info: #FFC107;
    //--color-warning: #ffc107;
    //--color-info-blue: #17A2B8;
    //--color-danger: #DC3545;
    --color-primary-contrast: var(--color-text-default);
    //--color-secondary-contrast: #FFF;
    //--color-success-contrast: #FFF;
    //--color-info-contrast: var(--color-text-default);
    //--color-warning-contrast: var(--color-text-default);
    //--color-danger-contrast: #FFF;
    //--color-light-contrast: var(--color-text-default);
    //--color-dark-contrast: #FFF;
    //--color-white-contrast: var(--color-text-default);
    //--color-default-contrast: #FFF;
    //
    //--color-primary-dark: #235275;
    //--color-secondary-dark: #424D56;
    //--color-success-dark: #12862B;
    //--color-info-dark: #967102;
    //--color-warning-dark: #967102;
    //--color-danger-dark: #cc2333;
    //--color-light-dark: #e4e8ec;
    //--color-dark-dark: #14191E;
    //--color-white-dark: #F8F9FA;
    //--color-default-dark: #1f232a;
    //
    //--color-primary-light: #3A88C4;
    //--color-secondary-light: #6E8090;
    //--color-success-light: #1EE048;
    //--color-info-light: #FBBD03;
    //--color-warning-light: #FBBD03;
    //--color-danger-light: #e15260;
    //--color-light-light: #fff;
    //--color-dark-light: #687F96;
    //--color-white-light: #FFF;
    //--color-default-light: #3b4552;

    --font-default: "AvenirLTProLight", sans-serif;
    --font-default-bold: "AvenirLTProBlack", sans-serif;
    --font-termina: "Termina-Regular", sans-serif;
    --font-termina-bold: "Termina-Bold", sans-serif;
//    --font-default-medium: "Roboto-Medium", sans-serif;

    --font-size-default: 1rem;

}
